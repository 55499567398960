export default {
  title: '汇智互娱',
  storageKey: 'hzhyService', // 存储主键
  whiteList: ['login', '404', '401', 'lock', 'platform'], // 配置无权限可以访问的页面，使用name值
  whiteTagList: ['/login', '/404', '/401', '/lock', '/platform'], // 配置不添加tags页面
  copyright: 'Copyright © 2019 pig4cloud.com. All rights reserved.',
  tiny: {
    name: '15302658606@163.com',
    password: 'Martin0622'
  }
}
