
export const apiBaseUrl = '/api'
export const codeBaseUrl = '/api/marketing/business'
export const authBaseUrl = '/api/auth'
export const adminBaseUrl = '/api/admin'
export const marketingBaseUrl = '/api/marketing/module'
export const authCodeBaseUrl = '/api/user/code'
export const marketingUrl = '/api/marketing'
export const authUserBaseUrl = '/api/user/store'
export const userBaseUrl = '/api/user'
