import request from '@http/request'
import { codeBaseUrl, authBaseUrl, adminBaseUrl, userBaseUrl, marketingBaseUrl } from '@api/baseUrl'
import store from '@/store'

// 获取图片和check相关字段
export function userLoginGetCode () : any {
  return request({
    url: `${codeBaseUrl}`
  })
}

// 登录check
export function userLoginCheck (data : {
  captchaType : string,
  pointJson : string
  token : string
}) : any {
  return request({
    url: `${codeBaseUrl}/check`,
    method: 'post',
    params: data
  })
}

// 登录
export function userLoginToken (
  params : {
    randomStr : string,
    code : string,
    'grant_type' : string
  },
  body : string
) : any {
  return request({
    url: `${authBaseUrl}/oauth/token`,
    headers: {
      isToken: false,
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic Ym9zczpib3Nz'
    },
    method: 'post',
    params,
    body
  })
}

export function userCheckIsRegister (username : string) {
  return request({
    url: `${adminBaseUrl}/user/infoByUsernameExcludeTenant/${username}`,
    method: 'get'
  })
}

// 根据用户id查询用户信息
export function userInquireInfo (id : number) {
  return request({
    url: `${adminBaseUrl}/user/${id}`,
    method: 'get'
  })
}

// 图片验证码
export function reqGet (body : any) {
  return request({
    url: '/api/code',
    method: 'get',
    body
  })
}

// 图片验证
export function reqCheck (body : any) {
  return request({
    url: '/api/code/check',
    method: 'get',
    body
  })
}

// 修改密码
export function changePassWord (body : any) {
  return request({
    url: `${adminBaseUrl}/user/editPassWD`,
    method: 'put',
    body
  })
}
// 获取oss上传凭证
export function getOSSToken () {
  return request({
    url: `${adminBaseUrl}/file/oss/sts`,
    method: 'get'
  })
}

// 获取sts上传凭证
export function getStsToken () {
  return request({
    url: `${adminBaseUrl}/file/oss/stsForEditing`,
    method: 'get'
  })
}

// 手机号验证码登录
export function loginPhone (body : any) {
  return request({
    url: `${authBaseUrl}/boss/phone/token/social?mobile=BOSSSMS@BOSSMOBILE_MERCHANT$$1000002$$MERCHANT$$${body.phone}&grant_type=boss_mobile&code=${body.code}&randomStr=${body.randomStr}`,
    method: 'post',
    headers: {
      isToken: false,
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic Ym9zczpib3Nz'
    }
  })
}

// 获取验证码
export function userLoginMobileCode (phone: string): any {
  return request({
    url: `${userBaseUrl}/mobile/code/${phone}`,
    headers: {
      isToken: false,
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic aDU6aDU=',
      tenantType: 1
    },
    method: 'get'
  })
}

// 运营商详情
export function spiInfoDetail () : any {
  return request({
    url: '/api/marketing/sp/spiInfo/detail',
    method: 'get'
  })
}
// 查看是否有消息
export function unReadInfo (spId:any) : any {
  return request({
    url: `${marketingBaseUrl}/spNotice/unRead/${spId}`,
    method: 'post'
  })
}

// 提交订单
export function videoDistributeOrder(body: any) {
	return request({
		url: `/api/marketing/merchant/order/info/submit/videoDistributeOrder`,
		method: 'post',
		body
	})
}

// 订单列表
export function orderList(body: any) {
	return request({
		url: `/api/marketing/merchant/shortVideoDistribute/list`,
		method: 'post',
		body
	})
}

// 支付
export function pay(orderId: any) {
	return request({
		url: `/api/marketing/order/pay/createNative/${orderId}`,
		method: 'get'
	})
}