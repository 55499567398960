import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/case',
    name: 'Case',
    component: () => import(/* webpackChunkName: "BroadcastReview" */ '../views/case/index.vue')
  },
  {
    path: '/buyServiceQrResult',
    name: 'BuyServiceQrResult',
    component: () => import(/* webpackChunkName: "BroadcastReview" */ '../views/buyServiceQrResult.vue')
  },
  {
    path: '/pact',
    name: 'Pact',
    component: () => import(/* webpackChunkName: "BroadcastReview" */ '../views/Pact.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'is-actives',
})

export default router
